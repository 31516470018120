<template>
  <div v-if="!loading">
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card flat class="mx-auto">
          <v-card-title>
            <strong>{{term.term}}</strong>
          </v-card-title>

          <div v-if="term.synonyms && term.synonyms.length>=1">
            <v-card-subtitle class="text-caption font-weight-light">Synonyms</v-card-subtitle>
            <v-card-text class="text-justify">
              <span v-for="(s,i) in term.synonyms" :key="s.synonym">
                <i>{{s.synonym}}</i><span v-if="i != term.synonyms.length - 1">, </span> 
              </span>
            </v-card-text>
          </div>
          
          <div v-if="similarTerms && similarTerms.length>1">
            <v-card-subtitle class="text-caption font-weight-light">Similar Terms</v-card-subtitle>
            <v-card-text class="text-justify">
              <span v-for="(st,i) in similarTerms" :key="st.definitionid">
                <router-link :to="{ name: 'definition_home', params: { definitionid: st.definitionid }}">{{st.term}}</router-link>
                <span v-if="i != similarTerms.length - 1">, </span> 
              </span>
            </v-card-text>
          </div>
          
          <v-card-text>
            <v-icon small>mdi-arrow-left</v-icon> 
            <router-link :to="{ name: 'definitions_landing' }">back to definitions</router-link>
          </v-card-text>
          
          <v-card-actions>
            <v-btn icon @click="openCouchbaseModal">
              <v-icon>mdi mdi-cloud-braces</v-icon>
            </v-btn>
          </v-card-actions>
          
          <CouchbaseView :document-id="`definition_group_${term.definitionid}`" :open="couchbaseModalOpen" @closeModal="closeCouchbaseModal"/>
          <!-- <button @click="openModal">Open Modal</button>
    <ModalComponent :modalOpen="modalOpen" @closeModal="closeModal" /> -->
  

        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="8" v-if="termExplanations && termExplanations.length">
        <v-row
          v-for="termExplanation in termExplanations"
          :key="termExplanation.definitionexplanationid"
        >
          
          <v-col 
            class="pb-1"
            v-for="(translation, index) of termExplanation.translations"
            :key="`termexplanation-${index}`"
            :cols="translationColumns(termExplanation.translations)"
          >
            <v-card flat class="mx-auto">
              <v-card-text class="pb-0" v-html="translation.definition"></v-card-text>
              <v-card-actions class="ma-0 pt-0">
                <div class="pl-1 text-caption">{{languageNameById(translation.languageid)}}</div>
                <v-btn color="blue lighten-2" text @click="translation.showreference = !translation.showreference" small>
                  <v-icon size="10">mdi-asterisk</v-icon>  References
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <Connections
                  v-if="translation.showreference"
                  :source-id="termExplanation.definitionexplanationid" 
                  :connection-type-id="connectionType.definitions" 
                  :showNoItemsMessage="true"
                  class="pl-2 pb-2"
                />
              </v-expand-transition>

              <debug class="pt-2" v-if="debug">
                <template slot="sub-heading">Translation</template>
                <template slot="info">
                  translation={{ translation }}
                </template>
              </debug>
            </v-card>
            
          </v-col>

          <div v-if="termExplanation.notes" class="pl-2 pr-2 pb-2">
            <div class="text-caption text-justify">NOTES: {{termExplanation.notes}}</div>
          </div>
        </v-row>

      </v-col>
      <v-col cols="12" sm="6" md="8" v-else>
        <v-card flat>
          <v-card-text>No exaplanations found.</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <debug class="pt-4" v-if="debug">
      <template slot="sub-heading">DefinitionsPage</template>
      <template slot="info">
        term={{ term }}<br/>
        similarTerms={{ similarTerms }}<br/>
        termExplanations={{ termExplanations }}<br/>
        connections={{ connections }}
      </template>
    </debug>

  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DocAdmin from '@/store/data'
import Vue from 'vue'
import Debug from '@/components/common/Debug'
import configs from '@/configs'
import Connections from '@/components/connections/Connections'
import CouchbaseView from '@/components/common/CouchbaseView.vue';

export default {
  name: 'DefinitonsPage',
  components: {
    Connections,
    Debug,
    CouchbaseView
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    connections: [],
    tab: {},
    term: undefined,
    termExplanations: [],
    similarTerms: undefined,
    loading: true,
    showReferences: {},
    couchbaseModalOpen: false
  }),
  computed: {
    ...mapGetters('languages', ['languageNameById']),
    ...mapGetters('app', ['mainDrawer']),
    ...mapState('languages', ['languages']),
    ...mapState('app', ['debug'])
  },
  async created() {
    if (!this.languages) {
      await this.$store.dispatch('languages/getLanguages')
    }
    this.getTerm()
  },
  methods: {
    closeCouchbaseModal() {
      this.couchbaseModalOpen = false;
    },
    openCouchbaseModal() {
      this.couchbaseModalOpen = true;
    },
    translationColumns(translations) {
      if (!translations) { return 12 }
      const numberOfTranslations = translations.length
      const columns = Math.round(12 / numberOfTranslations)

      return columns
    },
    colorIsDefault(isDefault) {
      const color = isDefault ? 'green': 'red';
      return color
    },
    async getTerm(){
      const term = await DocAdmin.definitions.get(this.$route.params.definitionid)
      this.term = term.data

      if (!this.term.definitionid) {
        console.error(`DefinitonsPage.getTerm no definition found.`)
        return
      }

      // get similar terms
      const terms = await DocAdmin.definitions.list({params: { limit: 10, search: this.term.term }})
      this.similarTerms = terms.data.results
      
      // get definition synonyms
      const synonyms = await DocAdmin.definitions.synonyms.list({ params: { definitionid: this.term.definitionid }})
      Vue.set(this.term, 'synonyms', synonyms.data.results)

      // get definition explanations
      const explanantionResponse = await DocAdmin.definitions.explanations.list({ params: { definitionid: this.term.definitionid }})
      this.termExplanations = explanantionResponse.data.results

      if (this.termExplanations.length) {

        this.termExplanations.forEach(async (element) => {
            // get translations
            const explanantionTranslationResponse = await DocAdmin.definitions.explanationTranslations.list({
              params: { definitionexplanationid: element.definitionexplanationid }
            })
            Vue.set(element, 'translations', explanantionTranslationResponse.data.results)
            element.translations.forEach((translation)=>{
              Vue.set(translation, 'showreference', false)
            })
        })
      }



      this.loading = false
    },
  }
}
</script>
