<template>
  <v-card-text v-if="Object.keys(connections).length" class="pa-0 text-caption">
    <slot name="sub-heading"></slot>
    <div v-for="connectionTypeId in Object.keys(connections)" :key="connectionTypeId">
      <strong>{{ getConnectionTypeById(connectionTypeId) }}: </strong>
      <span 
        v-for="(connection,i) in connections[connectionTypeId]"
        :key="`${connection.id}`"
      >
        <router-link v-if="connection.url" :to="connection.url">
          {{ connection.title ? connection.title : connection.id }}
        </router-link>
        <span v-if="!connection.url">{{ connection.title ? connection.title : connection.id }}</span>
        <span v-if="i+1 < connections[connectionTypeId].length">, </span>
      </span>
    </div>
  </v-card-text>
</template>

<script>
import connectionHelpers from '@/configs/connectionProperties'

export default {
  name: 'ConnectionsItem',
  props: {
    connections: {
      type: Object,
      default: null
    }
  },
  methods: {
    getConnectionTypeById(id) {
      return connectionHelpers.getConnectionTypeNameById(Number(id))
    }
  }
}
</script>