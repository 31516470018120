<template>
  <DefinitionListing/>
</template>
<script>
// document-item-manage
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import Debug from '@/components/common/Debug'
import DefinitionListing from '@/components/definitions/DefinitionListing'

export default {
  name: 'DefinitionsHome',
  components: {
    DefinitionListing,
    Debug
  },
}
</script>