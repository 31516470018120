<template>
  <v-dialog v-model="open" max-width="600">
    <v-card>
      <v-card-title>
        Couchbase Data
      </v-card-title>
      <v-card-text>
        <CouchbaseSettings @input="loadDocument($event)"/>
        <pre v-if="jsonData">{{ jsonData }}</pre>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="syncData">Sync Data</v-btn>
        <v-btn @click="closeModal">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
import axios from 'axios';
import CouchbaseSettings from '@/components/adminSettings/CouchbaseSettings'
export default {
  name: 'CouchbaseView',
  components: {
    CouchbaseSettings
  },
  props: ['open', 'documentId'],
  data() {
    return {
      jsonData: null
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    async loadDocument(doc) {
      console.log(JSON.stringify(doc));
      const documentName = doc.BUCKET_NAME;
      const response = await axios.get(`https://syncgateway.swaminarayan.faith/${documentName}/${this.documentId}`);
      if (response) {
        jsonData = response.data;
      }
    },
    syncData () {
      console.log('sync.data');
    }
  }
};
</script>