<template>
    <div>
      <v-select
        v-model="selectedItem"
        :items="items"
        item-text="BUCKET_NAME"
        label="Select an item"
        prepend-icon="mdi-account-search"
        v-on="listeners"
        return-object
      ></v-select>
<!-- 
      <v-autocomplete
        ref="document"
        v-model="model"
        :items="entries"
        :loading="isLoading"
        :search-input.sync="search"
        flat
        hide-no-data
        hide-details
        item-text="englishtitle"
        :label="label"
        placeholder="Start typing to Search"
        prepend-icon="mdi-account-search"
        v-on="listeners"
        return-object
      ></v-autocomplete> -->
    </div>
  </template>
  <script>
  import DocAdmin from '@/store/data'
  
  export default {
    name: 'CouchbaseSettings',
    props: {
      documentId: {
        type: Number,
        default: null
      },
      label: {
        type: String,
        default: null
      }
    },
    data: () => ({
      selectedItem: null,
      items: []
    }),
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: (e) => { 
            if (!e) { return }
            this.$emit("input", e)
          }
        }
      }
    },
    methods: {
      async fetchItems() {
        try {
          const response = await DocAdmin.sync.adminSettingsCouchbase.list();
          this.items = response.data;
        } catch (error) {
          console.error('Error fetching items:', error);
        }
      }
    },
    created() {
      this.fetchItems();
    }
  }
  </script>